'use strict';
import './jquery.js';
import 'bootstrap-sass';

import Masonry from 'masonry-layout';

$(window).on('load', function() { 
	if(document.querySelector(".grid")) {
	let msnry = new Masonry( '.grid', {
	  // options
	});	
	}
});
